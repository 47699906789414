import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/logos/acme-logo-dark.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/LandingPage/reusable-components/FooterClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LeftNav"] */ "/vercel/path0/src/components/NavigationMenu/ExternalNavbar/LeftNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginCTAButton"] */ "/vercel/path0/src/components/NavigationMenu/ExternalNavbar/LoginCTAButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileMenu"] */ "/vercel/path0/src/components/NavigationMenu/ExternalNavbar/MobileMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileMenuProvider"] */ "/vercel/path0/src/components/NavigationMenu/ExternalNavbar/MobileMenuContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileMenuOpen"] */ "/vercel/path0/src/components/NavigationMenu/ExternalNavbar/MobileMenuOpen.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeToggle"] */ "/vercel/path0/src/components/ThemeToggle.tsx");
