"use client"
import bottomSanFransiscoSkyline from '@public/assets/footer/bottom_san_fransisco_skyline.png';
import Image from 'next/image';
import { useState } from 'react';

const FooterClient = () => {

  const [imageHovered, setImageHovered] = useState<boolean>(false);

  return (
    <>
      <section className="relative">
        <Image
          src={bottomSanFransiscoSkyline}
          className="max-h-[26rem] h-full w-full mt-0"
          alt="san fransisco skyline top hiring"
          onMouseEnter={() => setImageHovered(true)}
          onMouseLeave={() => setImageHovered(false)}
        />
        <div className="pointer-events-none absolute h-[10rem] bottom-0 right-0 w-[6rem] bg-gradient-to-l from-[#000000]"></div>
        <div className="pointer-events-none absolute h-[10rem] bottom-0 left-0 w-[6rem] bg-gradient-to-r from-[#000000]"></div>
      </section>
    </>
  );
};

export default FooterClient;
